
import { defineComponent } from "vue";
import ActivityForm from "@/components/activity/ActivityForm.vue";
import { useUpdateActivity } from "@/graphql/activity/update.activity";

export default defineComponent({
  name: "UpdateActivity",
  components: { ActivityForm },
  props: ["item"],
  setup() {
    return {
      ...useUpdateActivity(),
    };
  },
});
