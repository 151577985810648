import {
  UpdateActivityInput,
  MutationUpdateActivityArgs,
  Activity,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { ACTIVITY_FIELDS } from "@/graphql/activity/activity.sdl";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { activeActivity } from "@/plugins/i18n";
import { setNumberFormat } from "@/graphql/session";

type UpdateActivityData = {
  updateActivity: Activity;
};
const UPDATE_ACTIVITY = gql`
    mutation UpdateActivity($image: Upload, $input: UpdateActivityInput!) {
        updateActivity(image: $image, input: $input){
            ${ACTIVITY_FIELDS}
        }
    }
`;

export const useUpdateActivity = () => {
  const dialog = ref<boolean>(false);
  const toast = useToast();
  const { t } = useI18n();
  const { loading, mutate, onDone } = useMutation<
    UpdateActivityData,
    MutationUpdateActivityArgs
  >(UPDATE_ACTIVITY, { context: { hasUpload: true } });
  onDone(({ data }) => {
    const success = !!data?.updateActivity;
    toast.add({
      severity: success ? "success" : "warn",
      summary: t("update.title"),
      detail: t(`update.${success ? "success" : "failed"}`),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
    dialog.value = false;
    if (success) {
      Object.assign(activeActivity.value, data.updateActivity);
      setNumberFormat();
    }
  });

  function submit(image: File, input: UpdateActivityInput) {
    void mutate({ image, input });
  }
  return { loading, dialog, submit };
};
