import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "p-text-bold",
  style: {"font-size":"18px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ActivityForm = _resolveComponent("ActivityForm")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_Button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = true)),
      "icon-pos": "right",
      icon: "pi pi-pencil"
    }, null, 512), [
      [_directive_tooltip, _ctx.$t('modify')]
    ]),
    _createVNode(_component_ActivityForm, {
      modelValue: _ctx.dialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialog) = $event)),
      item: _ctx.item,
      loading: _ctx.loading,
      onSubmit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submit($event[0], $event[1])))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("update.title")), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "item", "loading"])
  ], 64))
}